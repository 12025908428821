var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c("basic-container", [
        _c("div", { staticClass: "layTop" }, [
          _c(
            "div",
            { staticClass: "Title", staticStyle: { "margin-bottom": "20px" } },
            [_vm._v(_vm._s(_vm.$t("COMMUNICATION_LIST")))]
          )
        ]),
        _c("div", [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "middle",
                  attrs: {
                    model: _vm.formObj,
                    rules: _vm.formObjRules,
                    inline: true
                  }
                },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "el-row_style",
                      attrs: { gutter: 20, type: "flex" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "120px",
                                label: "工单编号:",
                                prop: "issueType"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入内容",
                                  disabled: true
                                },
                                model: {
                                  value: _vm.formObj.issueCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formObj, "issueCode", $$v)
                                  },
                                  expression: "formObj.issueCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "120px",
                                label: "问题类型:",
                                prop: "issueType"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入内容",
                                  disabled: true
                                },
                                model: {
                                  value: _vm.formObj.issueType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formObj, "issueType", $$v)
                                  },
                                  expression: "formObj.issueType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "120px",
                                label: "邮箱账号:",
                                prop: "emailAccount"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入内容",
                                  disabled: true
                                },
                                model: {
                                  value: _vm.formObj.emailAccount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formObj, "emailAccount", $$v)
                                  },
                                  expression: "formObj.emailAccount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "120px",
                                label: "联系电话:",
                                prop: "phone"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入内容",
                                  value:
                                    _vm.formObj.areaCode +
                                    " " +
                                    _vm.formObj.phone,
                                  disabled: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  this.roleSender === "0"
                    ? _c(
                        "el-row",
                        {
                          staticClass: "el-row_style",
                          attrs: { gutter: 20, type: "flex" }
                        },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "120px",
                                    label: "优先级:",
                                    prop: "priority"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "200px !important",
                                        marginRight: "5px"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updatePriority(
                                            _vm.formObj.issueCode,
                                            _vm.formObj.priority
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.formObj.priority,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formObj, "priority", $$v)
                                        },
                                        expression: "formObj.priority"
                                      }
                                    },
                                    _vm._l(_vm.options, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "120px",
                                    label: "工单状态:",
                                    prop: "status"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "200px !important",
                                        marginRight: "5px"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateStatus(
                                            _vm.formObj.issueCode,
                                            _vm.formObj.status
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.formObj.status,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formObj, "status", $$v)
                                        },
                                        expression: "formObj.status"
                                      }
                                    },
                                    _vm._l(_vm.status, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                          disabled: item.disabled
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formObj.describe
                    ? _c(
                        "el-row",
                        { staticClass: "el-row_style", attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "120px",
                                    label: "问题描述:",
                                    prop: "describe",
                                    disabled: ""
                                  }
                                },
                                [
                                  _c("div", { staticClass: "textarea-box" }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.formObj.describe)
                                      }
                                    })
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formObj.remark
                    ? _c(
                        "el-row",
                        { staticClass: "el-row_style", attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "120px",
                                    label: "备注:",
                                    prop: "remark"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "textarea-box" }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.formObj.remark)
                                      }
                                    })
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { ref: "middle" },
            [
              _c(
                "el-card",
                {
                  staticClass: "el-card-d",
                  staticStyle: { height: "100%" },
                  attrs: { shadow: "always" },
                  model: {
                    value: _vm.obj,
                    callback: function($$v) {
                      _vm.obj = $$v
                    },
                    expression: "obj"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "infinite-list-wrapper",
                      staticStyle: { overflow: "auto", height: "80vh" }
                    },
                    [
                      _c(
                        "el-timeline",
                        { attrs: { "infinite-scroll-disabled": "disabled" } },
                        _vm._l(_vm.allMessages, function(item, index) {
                          return _c(
                            "el-timeline-item",
                            {
                              key: index,
                              attrs: {
                                timestamp: item.createTime,
                                placement: "top"
                              }
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    item.roleSender === 0
                                      ? "客服"
                                      : _vm.formObj.customerName
                                  )
                                )
                              ]),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(item.information)
                                }
                              })
                            ]
                          )
                        }),
                        1
                      ),
                      _c("div", { staticStyle: { "margin-top": "2rem" } }, [
                        _c(
                          "div",
                          { staticStyle: { "min-height": "350px" } },
                          [
                            _c("quill-editor", {
                              ref: "myQuillEditor",
                              staticStyle: { height: "20vh" },
                              attrs: { options: _vm.editorOption },
                              on: {
                                blur: function($event) {
                                  return _vm.onEditorBlur($event)
                                },
                                focus: function($event) {
                                  return _vm.onEditorFocus($event)
                                },
                                change: function($event) {
                                  return _vm.onEditorChange($event)
                                },
                                ready: function($event) {
                                  return _vm.onEditorReady($event)
                                }
                              },
                              model: {
                                value: _vm.obj.information,
                                callback: function($$v) {
                                  _vm.$set(_vm.obj, "information", $$v)
                                },
                                expression: "obj.information"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", round: "" },
                                on: { click: _vm.submitForm }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Leave Word")) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", round: "" },
                                on: { click: _vm.resetForm }
                              },
                              [_vm._v(_vm._s(_vm.$t("Drop Out")))]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }