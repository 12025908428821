<template>
  <div class="pvhFactory">
    <basic-container>
      <div class="layTop">
        <div class="Title" style="margin-bottom:20px;">{{ $t('COMMUNICATION_LIST') }}</div>
      </div>
      <div>
        <!--回显页面-->
        <div>
          <el-form :model="formObj" ref="middle" :rules="formObjRules" :inline="true">
            <el-row class="el-row_style" :gutter="20" type="flex">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label-width="120px" label="工单编号:" prop="issueType">
                  <el-input
                      placeholder="请输入内容"
                      v-model="formObj.issueCode"
                      :disabled="true">
                  </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="问题类型:" prop="issueType">
                  <el-input
                      placeholder="请输入内容"
                      v-model="formObj.issueType"
                      :disabled="true">
                  </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="邮箱账号:" prop="emailAccount">
                  <el-input
                      placeholder="请输入内容"
                      v-model="formObj.emailAccount"
                      :disabled="true">
                  </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="联系电话:" prop="phone">
                  <el-input
                      placeholder="请输入内容"
                      :value="formObj.areaCode+' '+formObj.phone"
                      :disabled="true">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="el-row_style" :gutter="20" type="flex" v-if="this.roleSender==='0'">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label-width="120px" label="优先级:" prop="priority">
                  <el-select v-model="formObj.priority" style="width:200px !important;marginRight: 5px;"
                             @change="updatePriority(formObj.issueCode,formObj.priority)">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="120px" label="工单状态:" prop="status">
                  <el-select v-model="formObj.status" style="width:200px !important;marginRight: 5px;"
                             @change="updateStatus(formObj.issueCode,formObj.status)">
                    <el-option
                        v-for="item in status"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="el-row_style" :gutter="20" v-if="formObj.describe">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label-width="120px" label="问题描述:" prop="describe" disabled>
                  <div class="textarea-box">
                    <div v-html="formObj.describe"></div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="el-row_style" :gutter="20" v-if="formObj.remark">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label-width="120px" label="备注:" prop="remark">
                  <div class="textarea-box">
                    <div v-html="formObj.remark"></div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--沟通-->
        <div ref="middle">
          <el-card class="el-card-d" shadow="always" style="height: 100%" v-model="obj">
            <div class="infinite-list-wrapper" style="overflow:auto;height: 80vh;">
              <el-timeline infinite-scroll-disabled="disabled">
                <el-timeline-item
                    v-for="(item,index) in allMessages" :key="index"
                    :timestamp='item.createTime'
                    placement="top">
                  <div>{{ item.roleSender === 0 ? "客服" : formObj.customerName }}</div>
                  <div v-html="item.information"></div>
                </el-timeline-item>
              </el-timeline>
              <div style="margin-top: 2rem">
                <div style="min-height: 350px">
                  <quill-editor
                      v-model="obj.information"
                      ref="myQuillEditor"
                      :options="editorOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @change="onEditorChange($event)"
                      @ready="onEditorReady($event)"
                      style="height: 20vh"
                  >
                  </quill-editor>
                </div>
                <div>
                  <el-button type="primary" round style="" @click="submitForm">{{ $t('Leave Word') }}
                  </el-button>
                  <el-button type="danger" round @click="resetForm">{{ $t('Drop Out') }}</el-button>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </basic-container>
  </div>
</template>
<script>
//markdown
import {quillEditor} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {addIssueChat, editKeFu, issueChatList, list} from "@/api/issue/issue";

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      //校验
      formObjRules: {
        priority: [{required: true, message: "请选择优先级", trigger: 'blur'}],
      },
      //优先级下拉框
      options: [
        {
          label: 'low',
          value: 0
        },
        {
          label: 'middle',
          value: 1
        },
        {
          label: 'high',
          value: 2
        }
      ],
      status: [
        {
          label: '待处理',
          value: 0,
          disabled: true
        },
        {
          label: '处理中',
          value: 1,
        },
        {
          label: '已完成',
          value: 2
        }

      ],
      //表单信息
      formObj: {
        issueCode: '',
        issueType: '',
        remark: '',
        customerName: '',
        customerId: '',
        emailAccount: '',
        phone: '',
        parentMenu: '',
        subclassesMenu: '',
        priority: '',
        status: '',
        responseBy: '',
        personnelLevel: '',
        personnelRole: '',
        describe: '',
      },
      obj: {},
      describe: '',
      //路由传递信息
      routeSign: '', issueCode: '', roleSender: '', id: '',
      allMessages: [],
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{header: 1}, {header: 2}], // 1、2 级标题
            [{list: 'ordered'}, {list: 'bullet'}], // 有序、无序列表
            [{script: 'sub'}, {script: 'super'}], // 上标/下标
            [{indent: '-1'}, {indent: '+1'}], // 缩进
            [{direction: 'rtl'}], // 文本方向
            [{size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36']}], // 字体大小
            [{header: [1, 2, 3, 4, 5, 6]}], // 标题
            [{color: []}, {background: []}], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{align: []}], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ]
        },
        placeholder: '例：我按照系统的表格模板填写，并且导入了表格，但是上传以后显示我有些字段是没有填写的，这个怎么处理？'
      },
    };
  },
  created() {
    this.roleSender = this.$route.query.roleSender;
    this.getList();
    this.editInfo();
  },
  methods: {
    //提交数据
    submitForm() {
      if (!this.obj.information) {
        this.$message('不能发送空白消息')
      } else {
        this.routeSign = this.$route.query.sign;
        this.issueCode = this.$route.query.issueCode;
        this.roleSender = this.$route.query.roleSender;
        var info = {
          information: this.obj.information,
          issueCode: this.issueCode,
          roleSender: this.roleSender,
        };
        addIssueChat(info).then((res) => {
          this.request.post(res)
          this.obj.information = '';
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.getList();
        })
        this.getList();
      }
      this.getList();
    },
    // 失去焦点事件
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    // 获得焦点事件
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    // 准备富文本编辑器
    onEditorReady(quill) {
      // toolbar标题
      const titleConfig = [
        {Choice: '.ql-insertMetric', title: '跳转配置'},
        {Choice: '.ql-bold', title: '加粗'},
        {Choice: '.ql-italic', title: '斜体'},
        {Choice: '.ql-underline', title: '下划线'},
        {Choice: '.ql-header', title: '段落格式'},
        {Choice: '.ql-strike', title: '删除线'},
        {Choice: '.ql-blockquote', title: '块引用'},
        {Choice: '.ql-code', title: '插入代码'},
        {Choice: '.ql-code-block', title: '插入代码段'},
        {Choice: '.ql-font', title: '字体'},
        {Choice: '.ql-size', title: '字体大小'},
        {Choice: '.ql-list[value="ordered"]', title: '编号列表'},
        {Choice: '.ql-list[value="bullet"]', title: '项目列表'},
        {Choice: '.ql-direction', title: '文本方向'},
        {Choice: '.ql-header[value="1"]', title: 'h1'},
        {Choice: '.ql-header[value="2"]', title: 'h2'},
        {Choice: '.ql-align', title: '对齐方式'},
        {Choice: '.ql-color', title: '字体颜色'},
        {Choice: '.ql-background', title: '背景颜色'},
        {Choice: '.ql-image', title: '图像'},
        {Choice: '.ql-video', title: '视频'},
        {Choice: '.ql-link', title: '添加链接'},
        {Choice: '.ql-formula', title: '插入公式'},
        {Choice: '.ql-clean', title: '清除字体格式'},
        {Choice: '.ql-script[value="sub"]', title: '下标'},
        {Choice: '.ql-script[value="super"]', title: '上标'},
        {Choice: '.ql-indent[value="-1"]', title: '向左缩进'},
        {Choice: '.ql-indent[value="+1"]', title: '向右缩进'},
        {Choice: '.ql-header .ql-picker-label', title: '标题大小'},
        {Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一'},
        {Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二'},
        {Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三'},
        {Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四'},
        {Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五'},
        {Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六'},
        {Choice: '.ql-header .ql-picker-item:last-child', title: '标准'},
        {Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号'},
        {Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号'},
        {Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号'},
        {Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准'},
        {Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐'},
        {Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐'},
        {Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐'},
        {Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐'}
      ]
      for (let item of titleConfig) {
        let tip = document.querySelector('.quill-editor ' + item.Choice)
        if (!tip) continue
        tip.setAttribute('title', item.title)
      }
      console.log('editor ready!', quill)
    },
    // 内容改变事件
    onEditorChange({quill, html, text}) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    getList() {
      this.routeSign = this.$route.query.sign;
      this.issueCode = this.$route.query.issueCode;
      const xx = {
        pageNo: 1,
        pageSize: 100,
        total: 100,
        issueCode: this.issueCode,
      }
      issueChatList(xx).then(res => {
        if (res.data.data == null) {
          this.data = [];
          this.page.total = 0;
        } else {
          //debugger
          this.allMessages = res.data.data.items
        }
        console.log(res, 'res')
      }).catch(erro => {
        console.log(erro);
      })
    },
    //取消按钮
    resetForm() {
      this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
      this.roleSender = this.$route.query.roleSender;
      if (this.roleSender === "0") {
        if (this.formObj.status === 2) {//状态修改成已完成 给警告
          this.$confirm(this.$t('请确定该工单已经解决完成，一旦返回 不可逆向操作') + ":" + this.formObj.issueCode, this.$t('Tips'), {
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            type: "warning"
          }).then(() => {
            this.$router.push({path: '/help/issueService'});
          });
        } else {
          this.$router.push({path: '/help/issueService'});
        }
      } else {
        this.$router.push({path: '/help/issue'});
      }
    },
    //查看
    editInfo() {
      this.routeSign = this.$route.query.sign;
      this.issueCode = this.$route.query.issueCode;
      const xx = {
        pageNo: 1,
        pageSize: 100,
        total: 100,
        issueCode: this.issueCode,
      }
      list(xx).then(res => {
        var info = res.data.data.items;
        this.formObj = info[0];
      }).catch(erro => {
        console.log(erro);
      })
    },
    //修改优先级状态改为处理中
    updatePriority(issueCode, priority) {
      this.id = this.$route.query.id;
      console.log("修改的工单编号", issueCode);
      console.log("修改的优先级", priority);
      console.log("被修改的ID", this.id);
      var info = {
        issueCode: issueCode,
        priority: priority,
        id: this.id,
        status: 1,
      };
      editKeFu(info).then((res) => {
        //debugger
        this.request.post(res);
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getList();
      })
    },
    //修改工单状态
    updateStatus(issueCode, status) {
      this.id = this.$route.query.id;
      console.log("修改的工单编号", issueCode);
      console.log("修改的状态", status);
      console.log("被修改的ID", this.id);
      var info = {
        issueCode: issueCode,
        id: this.id,
        status: status,
      };
      console.log("修改的参数", info);
      editKeFu(info).then((res) => {
        //debugger
        this.request.post(res);
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getList();
      })
    },
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        let middle = this.$refs["middle"];
        middle.scrollTop = middle.scrollHeight;
      })
    })
  }
}
</script>

<style scoped lang="scss">
.textarea-box {
  background-color: #f5f7fa;
  color: #c0c4cc;
  cursor: not-allowed;
  -webkit-appearance: none;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: inline-block;
  font-size: inherit;
  line-height: 2.5rem;
  outline: 0;
  padding: 0 0.9375rem;
  border: 0.0625rem solid #DCDFE6;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 60vw;
}

</style>









